

  .text_bild_container {
    margin-bottom: 8rem;
    .figure {
      display: inline-block;
      width: 100%;
      margin: 0;
    }
    .caption {
      width: 100%;
      display: block;
      font-size: 0.85rem;
      padding: 1rem 1rem 2rem 1rem;
      margin: -5px 0 0 0;
      background: #fff;
      .headline {
        color: $primary-color;
        font-family: 'Chelsea Market';
        display: inline-block;
        font-size: 2rem;
        line-height: 1.2;
        width: 100%;
        padding: 1rem 0 1.5rem 1rem;
        a {
          color: $primary-color;
          &:hover {
            color: #000;
          }
        }
      }
      .text {
        font-family: 'Averia Sans Libre';
        display: inline-block;
        padding-bottom: 2rem;
        font-size: 1rem;
        font-weight: normal;
      }

    }
  }


  @include breakpoint(large up) {
    .text_bild_container {
      display: flex;
      flex-direction: row;
      // flex-wrap: wrap;
      align-items: center;
      .figure {
        flex: 1 1 50%;
        padding: 1rem 1rem 1rem 0;
        margin: 0 -6rem 0 0;
      }
      .caption {
        padding: 1rem 4.5rem 0.5rem 4.75rem;
        flex: 1 1 55%;

      }
    }

    .text_bild_container.image_right {
      flex-direction: row-reverse;
      align-items: center;
      .figure {
        flex: 1 1 50%;
        padding: 1rem 0 1rem 1rem;
        margin: 0 0 0 -6rem;
      }
      .caption {
        padding: 1rem 4.75rem 0.5rem 4.5rem;
        flex: 1 1 55%;
      }
    }
  }

