.bilderslider {
  padding-bottom: 10rem;

  .owl-carousel .owl-wrapper {
    display: flex !important;
  }
  .owl-carousel .owl-item {
    img {
      width: 100%;
      max-height: 750px;
      min-width: auto;
      object-fit: cover;
    }
  }
  .owl-dot {
    border: 8px solid #fff !important;
    &:hover {
      border: 8px solid grey !important;
    }
    &.active {
      border: 8px solid $primary-color !important;
    }
  }
  @include breakpoint(xxlarge) {
    max-width: 2600px;
    margin: 0 auto !important;
  }
}



.owl-dots {
  position: relative;
  top: -45px;
  text-align: center;
  z-index: 100000000;
}
.owl-dot {
  border: 8px solid grey !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 6px 0 6px;
  &:hover {
    border: 8px solid #fff !important;
  }
  &.active {
    border: 8px solid $primary-color !important;
  }
}

.owl-next, .owl-prev {
  visibility: hidden !important;
  @include breakpoint(large up) {
    visibility: visible !important;
  }
  display: inline-block;
  position: absolute;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  width: 65px;
  height: 50px;
  top: 43%;
  background: url("../images/arrow.png") !important;
}

.owl-next:hover,
.owl-prev:hover {
  background: url("../images/arrow_hover.png") !important;
}
.owl-prev {
  left: 100px;
  webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.owl-next {
  right: 100px;
}

.owl-next.disabled,
.owl-prev.disabled {
  display: none;
}

.owl-carousel.orbit {
  padding-bottom: 8rem;
   .owl-dots {
    position: relative;
    top: 1rem;
    padding-bottom: 6rem;
  }
  .owl-prev {
    left: 0;
  }
  .owl-next {
    right: 0;
  }
  .slide {
    .figure {
      display: inline-block;
      width: 100%;
      margin: 0;
    }
    .owl-caption {
      width: 100%;
      display: block;
      font-size: 0.85rem;
      padding: 1rem 1rem 2rem 1rem;
      margin: -5px 0 0 0;
      background: #fff;
      .headline {
        color: $primary-color;
        font-family: 'Chelsea Market';
        display: inline-block;
        font-size: 2rem;
        line-height: 1.2;
        width: 100%;
        padding: 1rem 0 1.5rem 1rem;
        a {
          color: $primary-color;
          &:hover {
            color: #000;
          }
        }
      }
      .text {
        font-family: 'Averia Sans Libre';
        display: inline-block;
        padding-bottom: 2rem;
        font-size: 1rem;
        font-weight: normal;
      }
      .link {
        font-family: 'Chelsea Market';
        display: inline-block;
        width: 100%;
        text-transform: uppercase;
        color: $primary-color;
        &:hover {
          color: #000;
        }
      }
    }

  }

  @include breakpoint(large up) {
    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      .figure {
        width: 65%;
        margin-left: 6rem;
      }
      .owl-caption {
        font-size: 0.85rem;
        padding: 3rem;
        width: 60%;
        margin: 0 6rem 0 -3rem;
      }
    }
  }



  .owl-next, .owl-prev {
    background: url("../images/arrow_hover.png") !important;
  }
  .owl-next:hover,
  .owl-prev:hover {
    background: url("../images/arrow.png") !important;
  }
}

