@import 'settings';
@import '../../frontend/fonts/foundation-icons/foundation-icons.css';
@import '../../../node_modules/foundation-sites/scss/foundation';
@import '../../../node_modules/motion-ui/src/motion-ui';




@font-face {
  font-family: 'Chelsea Market';
  font-style: normal;
  font-weight: 400;
  src: url('/theme/public/frontend/fonts/chelsea_market/chelsea-market-v5-latin-regular.eot');
  src: url('/theme/public/frontend/fonts/chelsea_market/chelsea-market-v5-latin-regular.eot?#iefix') format('embedded-opentype'),
  url('/theme/public/frontend/fonts/chelsea_market/chelsea-market-v5-latin-regular.woff2') format('woff2'),
  url('/theme/public/frontend/fonts/chelsea_market/chelsea-market-v5-latin-regular.woff') format('woff'),
  url('/theme/public/frontend/fonts/chelsea_market/chelsea-market-v5-latin-regular.ttf') format('truetype'),
}



@font-face {
  font-family: 'Averia Sans Libre';
  font-style: normal;
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-regular.eot');
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-regular.eot?#iefix') format('embedded-opentype'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-regular.woff2') format('woff2'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-regular.woff') format('woff'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-regular.ttf') format('truetype'),
}

@font-face {
  font-family: 'Averia Sans Libre';
  font-style: bold;
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700.eot');
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700.eot?#iefix') format('embedded-opentype'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700.woff2') format('woff2'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700.woff') format('woff'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700.ttf') format('truetype'),
}


@font-face {
  font-family: 'Averia Sans Libre';
  font-style: italic;
  font-style: bold;
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700italic.eot');
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700italic.eot?#iefix') format('embedded-opentype'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700italic.woff2') format('woff2'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700italic.woff') format('woff'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-700italic.ttf') format('truetype'),
}


@font-face {
  font-family: 'Averia Sans Libre';
  font-style: italic;
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-italic.eot');
  src: url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-italic.eot?#iefix') format('embedded-opentype'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-italic.woff2') format('woff2'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-italic.woff') format('woff'),
  url('/theme/public/frontend/fonts/averia_sans_libre/averia-sans-libre-v6-latin-italic.ttf') format('truetype'),
}

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
// @include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

button:focus {outline:0;}

body {
  overflow-x: hidden;
  min-width: 320px;
}

html {
  min-height: 100%;
  overflow-y: scroll;
}


.top-bar {
  font-size: 1rem;
  padding: .5rem 0 2.5rem 0;
  min-width: 320px;
}

#search_it-form_top {
 i {
    font-size: 2rem;
  }
  input{
    font-weight: normal;
    color: #000;
    margin-left: 1rem;
  }
}

// Placeholder

::placeholder {
  color: #fff;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #fff;
}
::-ms-input-placeholder {
  color: #fff;
}



.topbar-center-logo   {
  padding-bottom: 85px;
  a {
    position: relative;
    display: inline-block;
    background: url("../images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    height: 105px;
    b {
      position: absolute;
      font-family: 'Chelsea Market';
      left: 120px;
      top: 24px;
      font-size: 4rem;
    }
    i {
      font-family: 'Averia Sans Libre';
      font-style: normal;
      position: absolute;
      left: 123px;
      top: 96px;
      font-size: 1.5rem;
    }
  }
}



@media #{$medium-only} {

  .top-bar {
    font-size: 0.75rem;
  }


  .topbar-center-logo {
    padding-bottom: 56px;
    a {
      height: 65px;
      b {
        left: 80px;
        top: 16px;
        font-size: 2.5rem;
      }
      i {
        left: 83px;
        top: 64px;
        font-size: 0.8rem;
      }
    }
  }
}


@media #{$small-only} {
  .topbar-center-logo {
    margin-top: -2rem;
    padding-bottom: 30px;
    a {
      width: 77%;
      height: 90px;
      b {
        left: 30px;
        top: 12px;
        font-size: 1rem;
      }
      i {
        left: 0;
        top: 36px;
        font-size: 10px;
      }
    }
  }
}


.top-bar-left {
  button {
    margin-right: 0.85rem;
  }
}

.top-bar-right {
  text-align: right;
}

#header {
  // background: linear-gradient(to right, #669BCF 0%, #A3C3E2 100%);
  background-image: linear-gradient(to right, rgba(102,155,207, 0.55) 0%,rgba(102,155,207, 0.55) 100%), url("../images/header_back3.png");
   a {
    color: #fff;
  }
}

.menu-icon {
  color: #fff;//  !important;
  position: absolute;
  right: 29px;
  top: 105px;
  width: 23px;
  height: 23px;
  font-weight: bold;
  z-index: 10;
  &:after {
    height: 4px;
  }
  &:hover {
    &:after {
      background: #000;
      box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    }
  }
}



@media #{$small-only} {

  .menu-icon {
    right: 29px;
    top: 33px;
  }

  .topbar-center-logo a  {
    margin-left: 8px;
    height: 30px;
    span {
      font-size: 1rem;
      padding: 3px 0 0 35px;
    }
  }

  .close-button {
    position: absolute;
    right: 25px;
    font-size: 2.5rem;
    color: #fff !important;
    &:hover {
      color: #000 !important;
    }
  }



  .telefon {
    display: inline-block;
    color: #787878;
    margin: 6px 0 8px 100px;
    font-weight: bold;
    font-size: 0.85rem;
  }

}


.off-canvcas {


  &.search {
    border: 2px solid red;
    position: absolute;
    right: 0;
    top: -5px;
    z-index: 1;
    text-align: right;
    i {
      font-size: 2rem;
      color: #fff;
    }
    input {
      float: right;
      width: 60%;
      background: transparent;
      border: none;
      font-size: 1rem;
      margin: 5px 0 0 0 !important;
      color: #fff;
      text-transform: uppercase;
      box-shadow: none;
    }
  }
}


.close-button {
  position: absolute;
  right: 0.75rem;
  top: 6px;
  font-size: 2.5rem;
  color: #fff !important;
  &:hover {
    color: #000 !important;
  }
}

  .off-canvas .logo {
    position: relative;
    left: 1rem;
    top: 8px;
    color: #fff;
    font-size:  1rem;
  }


@media #{$medium-only} {
  .off-canvas .logo {
    top: 16px;
  }
}

#contentwrapper {
  padding: 80px 0 80px 0;

  a {
    color: $primary-color;
    &:hover {
      color: #000;
    }
  }

  h1 {
    margin: 0;
    padding: 0;
    line-height: 1;
    text-transform: uppercase;
  }
  h2 {
    font-weight: 300;
    margin: 0;
    padding: 0;
    line-height: 1;
    text-transform: uppercase;
  }
  h3 {
    font-weight: 300;
    margin: 5px 0 0 0;
    padding: 0;
    line-height: 1.5;
  }

  h6 {
    text-align: right;
    display: inline-block;
    width: 100%;
    font-weight: 400;
    margin: 0 0 30px 0;
  }
}

.headline {
  margin-left: -1rem;
  padding-bottom: 2rem;
  h1,
  h2 {
    color: #4A4A4A;
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: -2rem;
      content: " ";
      display: inline-block;
      width: 0.85rem;
      height: 5rem;
      background: $primary-color;
    }
  }
}


.backgroundwhite {
  background: #fff;
  padding: 3rem;
  margin-bottom: 4rem;
}

.textzweispaltig {
  h2 {
    display: inline-block;
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 2rem !important;
  }
  &.links {
    @media #{$small-only} {
      margin-bottom: 4rem;
    }
  }
}

.kontaktformular,
.textdownload,
.ueberschrift_text {
  margin-bottom: 6rem;
}

  ul.dropdown.menu {
    li {
      z-index: 10;
      &.search {
        position: absolute;
        right: 0;
        top: -5px;
        z-index: 1;
        text-align: right;
        i {
          font-size: 2rem;
          color: #fff;
        }
        input {
          float: right;
          width: 60%;
          background: transparent;
          border: none;
          font-size: 1rem;
          margin: 5px 0 0 0 !important;
          color: #fff;
          text-transform: uppercase;
          box-shadow: none;
          border-bottom: 1px solid #fff;
          &:focus {
            border-bottom: 1px solid #000;
          }
        }
      }

      a {
        text-transform: uppercase;
        font-family: 'Averia Sans Libre';
        font-size: 1.25rem;
        border-bottom: 1px solid  $primary-color;
        &.current {
          background: #003365;
        }

      }

      ul {
        z-index: 100;
        li {

         a {
            font-size: 1.2rem;
            text-transform: none;
            line-height: 1.2;
             &.current {
               background: $primary-color;
             }
             &:hover {
               background: $primary-color;
             }

          }
          ul {
            margin: 0;
            list-style: none;
            li {
              a {
                font-size: 1rem;
                padding: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

ul.accordion-menu {
  margin: 2.5rem 0 2rem 0;
  padding: 0;
  font-weight: 300;
  background: $primary-color;


    .search_it-form {
      border-top: 1px solid #fff;
      padding: 1.5rem 0 0rem 1rem;
      border-bottom: 1px solid $light-gray;
      i {
        padding-top: 1.5rem;
        font-size: 1.5rem;
        color: #fff;
      }
      input {
        float: right;
        width: calc(100% - 1.5rem);
        background: transparent;
        border: none;
        font-size: 1rem;
        color: #fff;
        text-transform: uppercase;
        box-shadow: none;
      }
    }


    li {
    border-bottom: 1px solid  $light-gray;
    a {
      color: #fff;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1rem;
      @media #{$medium-only} {
        font-size: 1.5rem;
      }
      line-height: 1.5rem;
      padding: 0.75rem 0 0.75rem 1rem;
      vertical-align: text-bottom;
      &.current {
      }
    }
    &:last-child {
      border: none;
    }
    .submenu-toggle {
      background: darken($primary-color, 5%);
    }

    ul {
      margin: 0 !important;
      li {
        a {
          background: darken($primary-color, 5%);
          font-size: 0.85rem;
          @media #{$medium-only} {
            font-size: 1.25rem;
          }
          line-height: 1.5rem;
          padding: 0.75rem 0 0.75rem 1.25rem !important;
        }
        .submenu-toggle {
          background: darken($primary-color, 10%);
        }
        ul {
          li {
            a {
              background: darken($primary-color, 10%);
              padding: 0.75rem 0 0.75rem 1.75rem !important;
              font-size: 0.85rem;
              @media #{$medium-only} {
                font-size: 1.25rem;
              }
            }
            &.is-active {
              color: #fff;
              background: darken($primary-color, 10%);
            }
          }
        }
        &.is-active {
          background: darken($primary-color, 5%);
        }
      }
    }
  }
}

.off-canvas {
  border: 4px solid $primary-color;
  padding: 0.5rem 0 4rem 0;
  -webkit-overflow-scrolling: touch;
}

.off-canvas a.adress,
.off-canvas a.phone {
  display: inline-block;
  padding: 0 1rem 0.25rem 1rem;
  color: #fff;
  font-size: 0.75rem;
}



  // Cookie Hinweis
.cc-window {
  border: 16px solid #000;
}


#footer {
  background-color: $primary-color;
  color: #fff;
  padding: 30px 0 20px 0;
  font-size: 0.85rem;
  width: 100%;


    .headline {
      margin-bottom: 0;
      padding-bottom: 0;
    }

  h4 {
    font-size: 1.25rem;
    color: #fff;
    margin-bottom: 1rem;
  }
  a {
    color: #fff;
    &:hover {
      color: #000;
    }
  }

.donboscocontainer {
  @media #{$medium-only} {
    padding: 1.5rem 0 0 0 ;
  }
}

  .footernavi {
    @media #{$small-only} {
      padding: 1.5rem 0 0 1rem;
      margin: 1rem 0 1.5rem 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }

    h4 {
    }

  }

  ul {
    margin-bottom: 2rem;
    margin: 0 0 2rem 0;
    padding: 0;
    }
    li {
      width: 100%;
      list-style: none;
      a {
        text-transform: uppercase;
        line-height: 1.25rem;
        font-size: 0.85rem;
        color: #fff;
        &.current {
          color: #000;
        }
      }
    }
  }


#footer_bottom {
  background: #003365;
  padding: 0.25rem;
  ul.menu {
    li {
      margin-right: 1rem;
      a {
        font-size: 0.85rem;
        line-height: 2.5;
        color: #fff;
        &:hover {
          color: #000;
        }
      }
    }
  }
}



#map_canvas {
  width: 100%;
  height: 40vh;
}

.routenplanerform {
  background: $light-gray;
  padding: 2rem 0 1rem 0;
  margin-bottom: -5rem;

}


@import 'text_bild';
@import 'text_bild_neu';
@import 'image_teaserslider';
@import 'aktuelles_termine';
