
.teaserwrapper {
  margin-bottom: 2.5rem;
}

.aktuelles_temine_teaser {
  margin-bottom: 3.5rem;


  .teaser-image {
    position: relative;
  }
  .teaser-date {
    position: absolute;
    right: 0;
    top: 0.5rem;
    padding: 0.25rem 0.5rem 0.25em 0.5rem;
    color: #fff;
    font-size: 0.85rem;
    background: $primary-color;
  }
  .teaser-headline {
    display: table;
    width: 100%;
    margin-top: 0;
    height: 3rem;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    background: $primary-color;
    span {
      display: table-cell;
      vertical-align: middle;
      color: #fff;
      font-family: 'Chelsea Market';
      text-transform: uppercase;
      font-size: 1.5rem;
      line-height: 1.2;
    }
  }
  .teaser-text {
    display: inline-block;
    margin: 2rem 1rem 1rem 1rem;
    p {
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .teaser-link {
    font-family: 'Chelsea Market';
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
    color: $primary-color;
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
      color: #000;
    }
  }
}

.backlink {
  float: right;
}



@include breakpoint(medium) {
  .aktuelles_temine_teaser {
    display: inline-block;

    .teaser-image {
      width: 100%;
    }
    .teaser-headline {
      height: auto;
      margin: 0.85rem 0 0 2rem;
      padding: 0;
      background: none;
      span {
        color: $primary-color;
      }
    }
    .teaser-text {
      margin: 1rem 1rem 1rem 2rem;
    }
    .teaser-link {
      margin: 0 0 0 2rem;
    }
  }
}

@include breakpoint(large) {
  .aktuelles_temine_teaser {

    position: relative;
    .teaser-image {

    }
    .teaser-headline {
      display: table;
      position: absolute;
      margin-top: -4rem;
      margin-left: 0;
      height: 3rem;
      padding: 0.25rem 0.5rem 0.25rem 1rem;
      width: 80%;
      background: $primary-color;
      span {
        font-family: 'Chelsea Market';
        color: #fff;
        font-size: 1.2rem;
        display: table-cell;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }
    .teaser-text {
      margin: 2rem 1rem 1rem 1rem;
    }
    .teaser-link {
      margin: 0 1rem 1rem 1rem;
    }
  }
}

.event_date {
  @include breakpoint(small) {
    margin-left: 1.2rem;
  }
  @include breakpoint(medium) {
    margin-left: 2.2rem;
  }
  @include breakpoint(large) {
    margin-left: 3.2rem;
  }
}

.event_image {
  margin-bottom: 2rem;
}